import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Mail } from  './mail';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  AUTH_SERVER = "https://6p5rimkzh9.execute-api.us-east-2.amazonaws.com";
  // AUTH_SERVER = "https://api.alexvanbeekum.com/api";
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };

  constructor(private _http: HttpClient) { }

  send(mail:Mail): Observable <Mail> {
    return this._http.post<Mail>(`${this.AUTH_SERVER}/emailservice`, mail, this.httpOptions).pipe();
  }
}
