import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgForm, FormGroup, FormBuilder} from "@angular/forms";
import {EmailService} from "../email.service";
import {Mail} from "../mail";
import {stringify} from "querystring";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  // values used for animation
  pagePrev      = 0;
  pagePrevDelay = 0;
  page          = 0;
  pageDelay     = 0;
  move          = 0;
  moveDelay     = 0;
  pageMax       = 3;
  blocked       = 1;
  review        = false;
  sent          = false;

  nextText = "Continue";

  // values used for form submission
  name    = "";

  emailPlaceholder = "ajvanbeekum@gmail.com";
  messageName      = "You know who";

  alreadySent  = false;

  errors       = [];
  nameReq      = true;
  nameError    = "";
  emailReq     = true;
  emailError   = "";
  messageReq   = true;
  messageError = "";
  errorClear   = false;


  constructor(private _email: EmailService) {
  }

  ngOnInit() {
  }

  send(form) {
    if (!this.alreadySent) {
      this.alreadySent = true;

      if (this.formValidate(form.value)) {
        this._email.send(form.value).subscribe((res) => {
          this.review = false;
          this.sent = true;
          this.page = 4;
          this.move = 0;
        });
        this.errorClear = true;
      } else {
        this.alreadySent = false;
        this.errorClear = true;
        setTimeout(() => {
          this.nameError = this.errors[1];
          this.emailError = this.errors[2];
          this.messageError = this.errors[3];
          this.errorClear = false;
        }, 500);
      }
    }
  }

  formValidate(form) {
    let start = true;

    console.log(form);

    this.errors = [];

    if (form.name.length <= 0) {
      this.errors[1] = "Please enter a name to submit";
      start = false;
    } else if (form.name.indexOf(' ') < 0 || form.name.indexOf(' ') == form.name.length - 1) {
      this.errors[1] = "First and last name please";
      start = false;
    } else if (form.name.indexOf('<') >= 0 || form.name.indexOf('>') >= 0) {
      this.errors[1] = "Please remove HTML Tags '<' '>'";
      start = false;
    }

    if (form.email.length <= 0) {
      this.errors[2] = "Please enter an email to submit";
      start = false;
    } else if (form.email.indexOf('@') > 64 || form.email.indexOf('@') <= 0 || (form.email.length - form.email.indexOf('@')) > 253 || (form.email.length - form.email.indexOf('@')) <= 1) {
      this.errors[2] = "Enter a valid email please";
      start = false;
    } else if (form.email.indexOf('<') >= 0 || form.email.indexOf('>') >= 0) {
      this.errors[2] = "Please remove HTML Tags '<' '>'";
      start = false;
    }

    if (form.message.length <= 0) {
      this.errors[3] = "Please enter an message to submit";
      start = false;
    } else if (form.message.indexOf('<') >= 0 || form.message.indexOf('>') >= 0) {
      this.errors[3] = "Please remove HTML Tags '<' '>'";
      start = false;
    }

    return start;
  }

  nameChange() {
    let curName = this.name;
    let space = curName.lastIndexOf(' ');
    if (space > 0) {
      curName = curName.substring(0, space) + curName.substring(space + 1);
      this.messageName = curName.substring(0, space);
    } else {
      this.messageName = curName;
    }
    this.emailPlaceholder = curName.toLowerCase() + "@gmail.com";
  }

  public autoGrow(e) {
    e.target.style.height = "0px";
    e.target.style.height = (e.target.scrollHeight) + "px";
  }

  formReview() {
    return {review: this.review};
  }

  getClass(index, type) {
    let comparePage;
    let compareMove;

    if (type == 0) {
      comparePage = this.page;
      compareMove = this.move;
    } else {
      comparePage = this.pageDelay;
      compareMove = this.moveDelay;
    }

    return {
      hidden: comparePage != index && index != comparePage && !this.review,
      prev: (index < comparePage || (index == comparePage && compareMove > 0)) && !this.review,
      next: index > comparePage || (index == comparePage && compareMove < 0),
      review: this.review
    }
  }

  getButtonClass(index) {
    return {
      open: this.page != index && index < this.blocked,
      blocked: index >= this.blocked,
      fadeAway: this.review || this.sent
    }
  }

  errorClass() {
    return {
      clear: this.errorClear
    }
  }

  prevClass() {
    return {
      prevLeave: this.page == 0 || this.review || this.sent
    }
  }

  nextClass() {
    return {
      prevLeave: this.sent
    }
  }

  continueText() {
    if (this.page < this.pageMax)
      return "Continue";
    else if (this.page == this.pageMax && !this.review)
      return "Review";
    else
      return "Send";
  }

  keyDown(event,form){
    if(event === 'Enter' || event === 'Tab'){
      this.nextPageNone(form);
    }
  }

  nextPageNone(form) {
    if(!this.sent) {
      let index = this.page + 1;
      if (this.review) {
        this.send(form);
      } else {
        this.nextPage(index);
      }
    }
  }

  nextPage(index) {
    if(!this.sent) {
      if (this.page < this.pageMax) {

        this.move = 1;
        setTimeout(() => this.moveDelay = 1, 100);
        setTimeout(() => {
          this.page = index;
          this.move = 0;
          if (this.page == this.blocked)
            this.blocked++;
        }, 400);

        setTimeout(() => {
          this.pageDelay = this.page;
          this.moveDelay = 0
          if (this.page == this.pageMax)
            this.nextText = "Review"
        }, 500);

        setTimeout(() => this.pagePrev = this.page, 1000);
        setTimeout(() => this.pagePrevDelay = this.page, 1100);
      } else if (this.page == this.pageMax) {
        this.move = 1;
        setTimeout(() => this.moveDelay = 1, 100);

        setTimeout(() => this.review = true, 600);
      }
    }
  }

  prevPageNone() {
    if(!this.sent) {
      let index = this.page - 1;
      if (!this.review)
        this.prevPage(index);
      else {
        this.review = false;
        this.move = 0;
        setTimeout(() => this.moveDelay = 0, 100);
      }
    }
  }

  prevPage(index) {
    if(!this.sent) {
      if (this.page > 0) {
        if (this.review)
          this.review = false;

        this.moveDelay = -1;
        setTimeout(() => this.move = -1, 100);
        setTimeout(() => {
          this.pageDelay = index;
          this.moveDelay = 0;
        }, 400);

        setTimeout(() => {
          this.page = this.pageDelay;
          this.move = 0;
          if (this.page == this.pageMax - 1)
            this.nextText = "Continue";
        }, 500);

        setTimeout(() => this.pagePrevDelay = this.pageDelay, 1000);
        setTimeout(() => this.pagePrev = this.pageDelay, 1100);
      }
    }
  }

  setPage(index) {
    if(!this.sent) {
      if (index < this.blocked && index != this.page) {
        if (index > this.page) {
          this.nextPage(index);
        } else {
          this.prevPage(index);
        }
      }
    }
  }
}
