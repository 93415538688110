import {Component, ElementRef, HostListener, ViewChild} from '@angular/core';
import { Router } from "@angular/router";
import { ModeService } from './mode.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent {

  // @ts-ignore
  @ViewChild('stickyMenu') menuElement;

  title  = 'portfolioSITE';
  sticky = false;
  stick  = true;
  menuPosition;
  arrow  = false;

  responsiveMenu = false;
  responsiveMenuDelay = false;
  clearMenu      = true;

  ngAfterViewInit() {
    this.menuPosition = this.menuElement.nativeElement.offsetTop
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    if (this.stick) {
      const windowScroll = window.pageYOffset;
      this.sticky = windowScroll >= this.menuPosition;
    }
  }

  constructor(public router: Router, private _mode: ModeService) {
  }

  scroll(dest: string) {
    document.querySelector(dest).scrollIntoView({behavior: "smooth", block: "start"});
  }

  setCurrent(identifier) {
    let choice = this.router.url === identifier;
    return {
      current: choice,
      nonCurrent: !choice
    };
  }

  routeChange() {
    if (this.router.url === '/') {
      this.sticky = false;
      this.stick = true;
    } else {
      this.sticky = true;
      this.stick = false;
    }
  }

  responsiveChange(){
    this.responsiveMenu = !this.responsiveMenu;
    if(!this.responsiveMenu){
      this.clearMenu = true;
      setTimeout(() =>
        this.responsiveMenuDelay = this.responsiveMenu, 500);
    } else {
      this.responsiveMenuDelay = this.responsiveMenu;
      setTimeout(() => this.clearMenu = false , 10);
    }
  }

  ulClass(){
    return {
      hidden: !this.responsiveMenuDelay,
      clear: this.clearMenu
    }
  }

  menuClass(){
    return {
      open: this.responsiveMenu
    }
  }

  closeMenu(){
    this.responsiveMenuDelay = false;
    this.responsiveMenu = false;
  }
}
