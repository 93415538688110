import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ModeService} from '../mode.service';

declare var require: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private _mode: ModeService) {
  }

  ngOnInit() {
    if(!this._mode.getFirstVisit()) {
      document.querySelector('#about').scrollIntoView({behavior: "auto", block: "start"});
    } else {
      this._mode.visit();
    }
  }
}
