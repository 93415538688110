import { Injectable } from '@angular/core';


export const light = {
  'mainBackgroundColor': '#E9EFF4',
  'highlightColor': '#EA2691',
  'color1': '#C8E0ED',
  'color2': '#80C6E5',
  'color3': '#249FEA',
  'color4': '#117099',
  'togglePos': '0',
  'togglePosReactive': '0',
  'togglePosNav': '0'
};

export const dark = {
  'mainBackgroundColor': '#191919',
  'highlightColor': '#C02379',
  'color1': '#222222',
  'color2': '#1B2D38',
  'color3': '#1D4F6D',
  'color4': '#2177AB',
  'togglePos': '27px',
  'togglePosReactive': '3vh',
  'togglePosNav': '15px'
};

@Injectable({
  providedIn: 'root'
})
export class ModeService {

  mode: boolean = true;
  firstVisit: boolean = true;

  constructor() { }

  modeSwitch(){
    this.modeChange(!this.mode);
  }

  modeChange(choice){
    this.mode = choice;
    if(choice){
      this.setTheme(light);
    } else {
      this.setTheme(dark)
    }
  }

  decodeMode(){
    if(this.mode){
      return 'light';
    } else{
      return 'dark'
    }
  }

  getMode(){
    return this.mode
  }

  visit(){
    this.firstVisit=false;
  }

  getFirstVisit(){
    return this.firstVisit;
  }

  private setTheme(theme: {}) {
    Object.keys(theme).forEach(k =>
      document.documentElement.style.setProperty(`--${k}`, theme[k])
    );
  }
}
